import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "../../App.scss";
import "./team.scss";


function Team() {
  return (
    <main className="farmsol">
      <Header />
      <div className="farmsol__team">

      </div>
      <Footer />
    </main>
  );
}

export default Team;