import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    useLocation,
    withRouter
} from 'react-router-dom';
import Landing from './pages/landing'
import About from './pages/about'
import Team from './pages/team'

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
      <Route path="/" component={Landing} exact />
      <Route path="/about" component={About} />
      <Route path="/team" component={Team} />
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
