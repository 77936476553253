import React from "react";
import { Link } from "react-router-dom";
import "../../App.scss";
import "./footer.scss";
// import Logo from "../../assets/img/logo.svg";

const Footer = () => {
  return (
    <section className="farmsol__footer">
      {/* <div className="farmsol__footer__secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-2">logo</div>
            <div className="col-md-4">logo</div>
            <div className="col-md-5">logo</div>
          </div>
        </div>
      </div> */}
      <div className="farmsol__footer__primary">
        <div className="container">
          <div className="row gx-5">
            <div className="col-md-4">
              <div className="farmsol__footer__primary__info float-md-left">
                <a href="/" className="my-0 mr-md-auto">
                  {/* <img src={Logo} alt="" /> */}
                  <h1>Sinola</h1>
                </a>
                <p>
                  Farmsol Lorem ipsum dolor, sit amet consectetur adipisicing
                  elit. Quasi quae molestiae esse possimus cupiditate iure iusto
                  minus nesciunt dicta officia sed sit eum voluptas consectetur
                  soluta, architecto dolores ipsa sequi..
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="farmsol__footer__primary__socials">
                <h3>Follow Us</h3>
                <ul className="nav">
                  <li className="nav-item mr-2">
                    <a
                      className="nav-link btn-outline-default"
                      target="_blank"
                      href="/"
                    >
                      <i
                        className="fab fa-twitter"
                        style={{ fontSize: "2.6rem" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>

                  <li className="nav-item mr-2">
                    <a
                      className="nav-link btn-outline-default"
                      target="_blank"
                      href="/"
                    >
                      <i
                        className="fab fa-discord"
                        style={{ fontSize: "2.6rem" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>

                  <li className="nav-item mr-2">
                    <a
                      className="nav-link btn-outline-default"
                      target="_blank"
                      href="/"
                    >
                      <i
                        className="fab fa-medium"
                        style={{ fontSize: "2.6rem" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>

                  <li className="nav-item mr-2">
                    <a
                      className="nav-link btn-outline-default"
                      target="_blank"
                      alt="Contract"
                      href="/"
                    >
                      <i
                        className="fab fa-github"
                        style={{ fontSize: "2.6rem" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="nav-item mr-2">
                    <a
                      className="nav-link btn-outline-default"
                      target="_blank"
                      alt="Contract"
                      href="/"
                    >
                      <i
                        className="fab fa-telegram"
                        style={{ fontSize: "2.6rem" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="nav-item mr-2">
                    <a
                      className="nav-link btn-outline-default"
                      target="_blank"
                      alt="Contract"
                      href="/"
                    >
                      <i
                        className="fab fa-linkedin"
                        style={{ fontSize: "2.6rem" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div className="farmsol__footer__primary__links">
                <h3>Company</h3>
                <ul>
                  <li>
                    <Link to="/team">Home</Link>
                  </li>
                  <li>
                    <Link to="/team">Team</Link>
                  </li>
                  <li>
                    <Link to="/team">About Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="farmsol__footer__primary__links">
                <h3>Help</h3>
                <ul>
                  <li>
                    <Link to="/team">Contact: admin@sinola.io</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
