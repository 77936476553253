import React from "react";
import { Link } from "react-router-dom";
import "../../App.scss";
import "./header.scss";
// import Logo from "../../assets/img/headerlogo.svg";

const Header = () => {
  return (
    <section className="farmsol__header">
      <div className="container">
        <div className="row justify-content-between">
          <div className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none farmsol__header__logo">
            <Link to="/">
              {/* <img src={Logo} alt="" /> */}
              <h1>Sinola</h1>
            </Link>
          </div>
          <div className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 farmsol__header__links">
            <ul className="d-flex">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 text-end farmsol__header__buttons">
            <a
              href="https://beta.web.kurobi.app/"
              target="_blank"
              rel="noreferrer"
              className="btn"
            >
              Button
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
