import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "../../App.scss";
import "./landing.scss";
import BannerImage from "../landing/img/header.jpeg";
import BannerImage800 from "../landing/img/header-800x.jpeg";
import BannerImage2400 from "../landing/img/header-2400x.jpeg";
import BannerImage1200 from "../landing/img/header-1200x.jpeg";
import FeaturesImageOne from "../landing/img/feat-1.png";
import FeaturesImageTwo from "../landing/img/feat-2.png";
import FeaturesImageThree from "../landing/img/feat-3.png";
function Landing() {
  return (
    <main className="farmsol">
      <Header />
      <div className="farmsol__landing">

        <section className="farmsol__landing__banner">
          <div className="container">
            <img
              src={BannerImage}
              srcset={`${BannerImage800} 800w, ${BannerImage1200} 1200w, ${BannerImage2400} 2400w`}
              sizes="100vw"
              alt="Happy man"
            />
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="farmsol__landing__banner__manifest">
                  <h1>
                    Banking on <span>Crypto</span>
                  </h1>
                  <div className="farmsol__landing__banner__benefits">
                    <div className="item">
                      <h2>Earn Interest</h2>
                      <div className="item__numbers">
                        <h3 className="paid">12%</h3>
                        <h4>
                          Interest <br /> Paid Out Daily
                        </h4>
                      </div>
                    </div>
                    <div className="item">
                      <h2>Borrow Instantly</h2>
                      <div className="item__numbers">
                        <h3 className="colla">18</h3>
                        <h4>
                          Cryptocurrencies <br /> available as collateral{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="farmsol__landing__banner__buttons">
                    <a href="/" className="btn">Create Account</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </section>

        <section className="farmsol__landing__addons">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="farmsol__landing__addons__item">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 18C4.44772 18 4 18.4477 4 19V21C4 21.5523 4.44772 22 5 22H5.90361L5.08072 34.7493C5.03701 35.4266 5.57457 36 6.25327 36H13.7467C14.4254 36 14.963 35.4266 14.9193 34.7493L14.0964 22H15C15.5523 22 16 21.5523 16 21V19C16 18.4477 15.5523 18 15 18H5Z"
                      fill="#9EB3EE"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19 18C18.4477 18 18 18.4477 18 19V21C18 21.5523 18.4477 22 19 22H19.9036L19.0807 34.7493C19.037 35.4266 19.5746 36 20.2533 36H27.7467C28.4254 36 28.963 35.4266 28.9193 34.7493L28.0964 22H29C29.5523 22 30 21.5523 30 21V19C30 18.4477 29.5523 18 29 18H19Z"
                      fill="#9EB3EE"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34 18C33.4477 18 33 18.4477 33 19V21C33 21.5523 33.4477 22 34 22H34.9036L34.0807 34.7493C34.037 35.4266 34.5746 36 35.2533 36H42.7467C43.4254 36 43.963 35.4266 43.9193 34.7493L43.0964 22H44C44.5523 22 45 21.5523 45 21V19C45 18.4477 44.5523 18 44 18H34Z"
                      fill="#9EB3EE"
                    ></path>
                    <path
                      d="M1.0202 38.8586C1.09058 38.3659 1.5125 38 2.01015 38H45.9898C46.4875 38 46.9094 38.3659 46.9798 38.8586L47.8369 44.8586C47.923 45.461 47.4555 46 46.847 46H1.15301C0.544463 46 0.0769983 45.461 0.16306 44.8586L1.0202 38.8586Z"
                      fill="#1E4DD8"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.715419 8.91582C0.291046 9.04179 0 9.43179 0 9.87447V15C0 15.5523 0.447714 16 0.999999 16H47C47.5523 16 48 15.5523 48 15V9.87425C48 9.43168 47.7091 9.04174 47.2849 8.91568L25.7206 2.5076C24.6059 2.17634 23.4189 2.17617 22.304 2.50712L0.715419 8.91582ZM24 13C25.6569 13 27 11.6568 27 9.99998C27 8.34313 25.6569 6.99998 24 6.99998C22.3431 6.99998 21 8.34313 21 9.99998C21 11.6568 22.3431 13 24 13Z"
                      fill="#1E4DD8"
                    ></path>
                  </svg>
                  <div className="farmsol__landing__addons__item__text">
                    <h5>Licensed & regulated digital assets institution</h5>
                    <a href="/" className="btn">
                      View Licences
                  </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="farmsol__landing__addons__item d-flex">
                  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.99224 8.38568C4.56067 8.70917 3.5 9.96418 3.5 11.4318V20.9408C3.5 31.5425 9.1914 41.4586 18.7089 46.129C21.554 47.5251 23.8484 48.54 24.5058 48.54C25.1631 48.54 27.4551 47.5257 30.2974 46.1303C39.8116 41.4592 45.5 31.5449 45.5 20.9459V11.4318C45.5 9.96418 44.4393 8.70917 43.0078 8.38568C40.7903 7.88459 37.5178 7.00851 35 5.75743C33.5122 5.01813 32.0243 4.14788 30.6298 3.33223C28.0878 1.84541 25.8561 0.540039 24.5 0.540039C23.1439 0.540039 20.9122 1.84541 18.3702 3.33223C16.9757 4.14788 15.4878 5.01813 14 5.75743C11.4822 7.00851 8.20975 7.88459 5.99224 8.38568ZM31.4765 21.3967C31.9497 20.894 31.9257 20.1029 31.423 19.6298C30.9203 19.1566 30.1292 19.1806 29.656 19.6833L22.4949 27.292L19.3839 24.181C18.8957 23.6929 18.1043 23.6929 17.6161 24.181C17.128 24.6692 17.128 25.4606 17.6161 25.9488L21.4183 29.751C22.0373 30.37 23.0455 30.3547 23.6455 29.7173L31.4765 21.3967Z" fill="#9EB3EE"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24.5 37.54C31.6797 37.54 37.5 31.7197 37.5 24.54C37.5 17.3603 31.6797 11.54 24.5 11.54C17.3203 11.54 11.5 17.3603 11.5 24.54C11.5 31.7197 17.3203 37.54 24.5 37.54ZM31.4765 21.3967C31.9497 20.894 31.9257 20.1029 31.423 19.6298C30.9203 19.1566 30.1292 19.1806 29.656 19.6833L22.4949 27.292L19.3839 24.181C18.8957 23.6929 18.1043 23.6929 17.6161 24.181C17.128 24.6692 17.128 25.4606 17.6161 25.9488L21.4183 29.751C22.0373 30.37 23.0455 30.3547 23.6455 29.7173L31.4765 21.3967Z" fill="#1E4DD8"></path></svg>
                  <div className="farmsol__landing__addons__item__text">
                    <h5>Licensed & regulated digital assets institution</h5>
                    <a href="/" className="btn">
                      View Licences
                  </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="farmsol__landing__addons__item">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 36.0863C2 27.7267 8.57142 20.95 16.6777 20.95H19.3223C25.7462 20.95 31.2062 25.2057 33.1958 31.1323C33.033 30.9607 32.8588 30.8064 32.6741 30.6706C32.0692 30.2256 31.382 30 30.6316 30C29.6276 30 28.7041 30.3823 28.0312 31.0765C27.3663 31.7626 27 32.6998 27 33.7157C27 34.7614 27.4097 35.7186 28.2894 36.7281C28.9088 37.4389 29.7414 38.1582 30.7066 38.95H4.77686C3.24325 38.95 2 37.6679 2 36.0863Z" fill="#9EB3EE"></path><path d="M18.1494 1C13.455 1 9.64941 4.80558 9.64941 9.5C9.64941 14.1944 13.455 18 18.1494 18C22.8438 18 26.6494 14.1944 26.6494 9.5C26.6494 4.80558 22.8438 1 18.1494 1Z" fill="#9EB3EE"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M33.5 47C40.4036 47 46 41.4036 46 34.5C46 27.5964 40.4036 22 33.5 22C26.5964 22 21 27.5964 21 34.5C21 41.4036 26.5964 47 33.5 47ZM36.3684 30C37.3724 30 38.2958 30.3823 38.9687 31.0765C39.6337 31.7626 40 32.6998 40 33.7157C40 34.7614 39.5904 35.7186 38.7107 36.728C37.9239 37.6311 36.7929 38.5476 35.4835 39.6089L35.4833 39.609L35.4723 39.618C35.0276 39.9785 34.5244 40.3864 34.0024 40.8206C33.8635 40.9362 33.685 41 33.5 41C33.3149 41 33.1365 40.9362 32.9974 40.8204C32.4714 40.3829 31.9647 39.9722 31.5176 39.6098L31.517 39.6093C30.2073 38.5478 29.0763 37.6311 28.2894 36.7281C27.4097 35.7186 27 34.7614 27 33.7157C27 32.6998 27.3663 31.7626 28.0312 31.0765C28.7041 30.3823 29.6276 30 30.6316 30C31.382 30 32.0692 30.2256 32.6741 30.6706C32.9795 30.8952 33.2562 31.1701 33.5 31.4907C33.7439 31.17 34.0205 30.8952 34.3258 30.6706C34.9308 30.2256 35.618 30 36.3684 30Z" fill="#1E4DD8"></path></svg>
                  <div className="farmsol__landing__addons__item__text">
                    <h5>Licensed & regulated digital assets institution</h5>
                    <a href="/" className="btn">
                      View Licences
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="farmsol__landing__numbers">
          <div className="container">
            <div className="row">
              <div className="col farmsol__landing__numbers__item">
                <h3>$12B+</h3>
                <h4>Assets under
management</h4>
              </div>
              <div className="col farmsol__landing__numbers__item">
                <h3>1M+</h3>
                <h4>Nexio Users</h4>
              </div>
              <div className="col farmsol__landing__numbers__item">
                <h3>40+</h3>
                <h4>Fiat currencies
available </h4>
              </div>
              <div className="col farmsol__landing__numbers__item">
                <h3>200+</h3>
                <h4>Available in 200+
jurisdictions </h4>
              </div>
              <div className="col farmsol__landing__numbers__item">
                <h3>100K+</h3>
                <h4>Social media
community</h4>
              </div>
            </div>
          </div>
        </section>



        <section className="farmsol__landing__features one off-white">
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-6">
                <div className="farmsol__landing__features__image left">
                  <img src={FeaturesImageOne} alt=""/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="farmsol__landing__features__text">
                  <h1>
                    Earn <span>Interest</span>
                  </h1>
                  <h2>Earn daily interest on your crypto and EUR, GBP and USD </h2>

                  <ul>
                    <li>Up to <span>12%</span> annual interest</li>
                    <li>Unique daily payout</li>
                    <li>$375 million insurance on all custodial assets</li>
                    <li>Add or withdraw funds at any time</li>
                  </ul>

                  <a href="/">Earn on Crypto <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg> </a>
                  <a href="/">Earn on EUR, GBP and USD <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg> </a>

                  <hr/>

                  <h6>Explore Our Institutional Offerings </h6>

                </div>
              </div>
            </div>
          </div>
        </section>

        


        <section className="farmsol__landing__features one white">
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-6">
                <div className="farmsol__landing__features__text">
                  <h1>
                    Nexo Card
                  </h1>
                  <h2>Your Digital Assets Are Just a Swipe Away </h2>
                  <h3>The only card that lets you spend the value of your digital assets without having to sell them. Get instant cashback on all purchases and take advantage of flexible repayment options. </h3>
                   <a href="/">Get Your Nexo Card <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg> </a>

                  <ul>
                    <li>Up to <span>12%</span> annual interest</li>
                    <li>Unique daily payout</li>
                    <li>$375 million insurance on all custodial assets</li>
                    <li>Add or withdraw funds at any time</li>
                  </ul>

                </div>
              </div>
              <div className="col-md-6">
                <div className="farmsol__landing__features__image">
                  <img src={FeaturesImageThree} alt="" style={{maxWidth:'70%'}}/>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="farmsol__landing__features one off-white">
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-6">
                <div className="farmsol__landing__features__text">
                  <h1>
                    Borrow <span>Cash
or Stablecoins</span>
                  </h1>
                  <h2>Collateralize your crypto and borrow instantly with Nexo's crypto credit lines  </h2>

                  <ul>
                    <li>Interest starting from 5.9% APR*</li>
                    <li>$375 million insurance on all custodial assets</li>
                    <li>A minimum of $50 and a maximum of $2 million</li>
                    <li>Automatic approval, no credit checks</li>
                  </ul>

                  <a href="/">Borrow Cash or Stablecoins
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg> </a>
                  

                  <hr/>

                  <h6>Explore Our Institutional Offerings </h6>

                </div>
              </div>
              <div className="col-md-6">
                <div className="farmsol__landing__features__image right">
                  <img src={FeaturesImageTwo} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <Footer />
    </main>
  );
}

export default Landing;
