import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "../../App.scss";
import "./about.scss";

import AntoniImg from './img/antoni-picture.jpeg'
import DartBoard from './svgs/dart-board.svg'
import VisionSvg from './svgs/eye.svg'
import ValuesSvg from './svgs/diamond.svg'

import BitGoLogo from './svgs/logos/logo-bitgo.svg'
import LedgerLogo from './svgs/logos/logo-ledger.svg'
import PaxosLogo from './svgs/logos/logo-paxos.svg'
import BlockchainLogo from './svgs/logos/logo-blockchain.svg'
import CircleLogo from './svgs/logos/logo-circle.svg'
import FireblocksLogo from './svgs/logos/logo-fireblocks.svg'
import BCBLogo from './svgs/logos/logo-bcb.svg'
import TerraLogo from './svgs/logos/logo-terra.svg'
import BrinkLogo from './svgs/logos/brink_logo.png'
import NomicsLogo from './svgs/logos/logo-nomics.svg'
import SecuritizeLogo from './svgs/logos/logo-securitize.svg'
import BraveLogo from './svgs/logos/logo-brave.svg'
import JumioLogo from './svgs/logos/logo-jumio.svg'

import BitcoinFoundation from './svgs/logos/logo-bitcoin-foundation.svg'
import CrytoValley from './svgs/logos/logo-crypto-valley.svg'
import SwissSvg from './svgs/logos/logo-swiss-fintech.png'
import SwissAssociationSvg from './svgs/logos/logo-sfta.svg'
import GDFLogo from './svgs/logos/gdf-logo-combined-teal.png'
import ITSALogo from './svgs/logos/logo-itsa.png'
import EFALogo from './svgs/logos/efassociation_logo_transparent.png'

import NexoCareers from './svgs/logos/nexo-careers.jpeg'
import PDFSvg from './svgs/nexo-pdf.svg'
import BlankDocumentSvg from './svgs/blank-document.svg'
import GoogleDocsSvg from './svgs/google-docs.svg'
import RightArrowSvg from './svgs/right-arrow.svg'

import CryptoPowerImg from './svgs/logos/unleash-crypto-power.png'


function About() {
  return (
    // <main className="farmsol">
    //   <Header />
    //   <div className="farmsol__about">

    //   </div>
    //   <Footer />
    // </main>

    <div className="nexo-about-us-page">
      <Header />
      <section className="one">
        <h2 className="top-text">
          Disrupting the financial system, one bit at a time
        </h2>
      </section>

      <section className="two">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <span className="nexo-largest-text">
                Nexo is the world's largest and most trusted lending institution
                in the digital finance industry
              </span>
            </div>
            <div className="col-6">
              <p>
                Since 2018 Nexo has strived to bring professional banking
                services to the world of digital assets. Leveraging the best of
                the team's years of experience in FinTech along with the power
                of blockchain technology, Nexo empowers thousands of people to
                harness the value behind their crypto assets, shaping a new,
                better financial system.
              </p>

              <p>
                Nexo currently manages $12+ billion in assets for 1,000,000+
                users across 200 jurisdictions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="three">
          <div>
            <p className="amount">$12B+</p>
            <span>Assets under management</span>
          </div>
          <div>
            <p className="amount">1M+</p>
            <span>Nexo users</span>
          </div>
          <div>
            <p className="amount">40+</p>
            <span>Fiat currencies available</span>
          </div>
          <div>
            <p className="amount">200+</p>
            <span>Available in 200+ jurisdictions</span>
          </div>
          <div>
            <p className="amount">100K+</p>
            <span>Social Media community</span>
          </div>
        </section>
      </div>

      <section className="four">
        <div className="container">
          <img src={AntoniImg} alt="antoni-img" className="img-fluid" />
          <div className="text">
            <h1>Creating the Future of Finance Today</h1>
            <div className="info">
              <p>Antoni Trenchev</p>
              <span>Co-Founder & Managing Partner</span>
            </div>
          </div>
        </div>
      </section>

      <section className="five">
        <div className="container">
          <h1>Mission, Vision & Values</h1>
          <h3>A service made with our clients in mind</h3>
          <div className="row">
            <div className="col-4">
              <img src={DartBoard} alt="dart-board" className="img-fluid" />
              <div className="text">
                <span>01.</span>
                <h2>Mission</h2>
                <p>
                  Nexo is committed to successfully solving inefficiencies in
                  the lending markets by creating innovative, convenient and
                  sustainable solutions. By harnessing the potential behind
                  blockchain technology, Nexo is pioneering a new digital
                  financial system.
                </p>
                <p></p>
              </div>
            </div>
            <div className="col-4">
              <div className="text">
                <span>02.</span>
                <h2>Vision</h2>
                <p>
                  We see a future for the financial world where all assets are
                  tokenized on-chain and people have efficient ways to transfer
                  or manage their funds. At Nexo, we always look ahead which is
                  why we’ve designed our products to be seamless, secure and
                  instant -- the banking services of tomorrow.
                </p>
              </div>
              <img src={VisionSvg} alt="dart-board" className="img-fluid" />
            </div>
            <div className="col-4">
              <img src={ValuesSvg} alt="dart-board" className="img-fluid" />
              <div className="text">
                <span>03.</span>
                <h2>Values</h2>
                <p>
                  Nexo’s values are rooted in efficiency, transparency and
                  inclusivity. This is reflected in our fully-automated
                  products, transparent processes and our extensive efforts to
                  develop a truly global service that makes wealth creation
                  opportunities available to everyone, providing accessible
                  banking services for the unbanked.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="six">
        <div className="container">
          <h2>Strategic Partners</h2>
          <div className="logos">
            <p>
              <img src={BitGoLogo} alt="logo-bitgo" className="img-fluid" />
            </p>
            <p>
              <img src={LedgerLogo} alt="logo-ledger" className="img-fluid" />
            </p>
            <p>
              <img src={PaxosLogo} alt="logo-paxos" className="img-fluid" />
            </p>
            <p>
              <img
                src={BlockchainLogo}
                alt="logo-blockchain"
                className="img-fluid bigger"
              />
            </p>
            <p>
              <img src={CircleLogo} alt="logo-circle" className="img-fluid" />
            </p>
            <p>
              <img
                src={FireblocksLogo}
                alt="logo-fireblocks"
                className="img-fluid bigger"
              />
            </p>
            <p>
              <img src={BCBLogo} alt="logo-bcb" className="img-fluid bigger" />
            </p>
            <p>
              <img src={TerraLogo} alt="logo-terra" className="img-fluid" />
            </p>
            <p>
              <img src={BrinkLogo} alt="logo-brink" className="img-fluid" />
            </p>
            <p>
              <img src={NomicsLogo} alt="logo-nomics" className="img-fluid" />
            </p>
            <p>
              <img
                src={SecuritizeLogo}
                alt="logo-securitize"
                className="img-fluid bigger"
              />
            </p>
            <p>
              <img src={BraveLogo} alt="logo-brave" className="img-fluid" />
            </p>
            <p>
              <img src={JumioLogo} alt="logo-jumio" className="img-fluid" />
            </p>
          </div>
        </div>
      </section>

      <section className="seven">
        <h2>Memberships</h2>
        <div className="container">
          <div className="logos">
            <p>
              <img
                src={BitcoinFoundation}
                alt="logo-bitcoin-foundation"
                className="img-fluid smaller"
              />
            </p>
            <p>
              <img
                src={CrytoValley}
                alt="logo-bitgo"
                className="img-crypto-valley"
              />
            </p>
            <p>
              <img
                src={SwissAssociationSvg}
                alt="logo-swiss-association"
                className="img-fluid bigger"
              />
            </p>
            <p>
              <img
                src={SwissSvg}
                alt="logo-swiss-fintech"
                className="img-fluid smaller"
              />
            </p>
            <p>
              <img src={GDFLogo} alt="logo-gdf" className="img-fluid smaller" />
            </p>
            <p>
              <img
                src={ITSALogo}
                alt="logo-itsa"
                className="img-fluid bigger"
              />
            </p>
            <p>
              <img src={EFALogo} alt="logo-efa" className="img-fluid" />
            </p>
          </div>
        </div>
      </section>

      <section className="eight">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h1>Careers</h1>
              <h3>
                We are looking for exceptionally talented people to join our
                team
              </h3>
              <p>
                Imagine yourself contributing to the development of one of the
                most dynamic blockchain-based FinTech companies in the world,
                redefining how financial services are conducted. This innovative
                company is Nexo.
              </p>
              <button>View all openings</button>
            </div>
            <div className="col-6">
              <img src={NexoCareers} alt="nexo-careers" />
            </div>
          </div>

          <div className="row sub-section">
            <div className="col-4">
              <div className="group">
                <span className="circle">
                  <img src={PDFSvg} alt="nexo-pdf" className="nexo-sub-icon" />
                </span>

                <div>
                  <h2>Business Overview</h2>
                  <p>
                    <span>Download</span>
                    <img
                      src={RightArrowSvg}
                      className="right-arrow"
                      alt="right-arrow"
                    />
                  </p>
                  <span>PDF * 12MB </span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <span className="circle">
                  <img
                    src={GoogleDocsSvg}
                    alt="nexo-pdf"
                    className="nexo-sub-icon"
                  />
                </span>
                <div>
                  <h2>Whitepaper</h2>
                  <p>
                    <span>Download</span>
                    <img
                      src={RightArrowSvg}
                      className="right-arrow"
                      alt="right-arrow"
                    />
                  </p>
                  <span>PDF * 5MB </span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <span className="circle">
                  <img
                    src={BlankDocumentSvg}
                    alt="nexo-pdf"
                    className="nexo-sub-icon"
                  />
                </span>
                <div>
                  <h2>Token Terms</h2>
                  <p>
                    <span>Download</span>
                    <img
                      src={RightArrowSvg}
                      className="right-arrow"
                      alt="right-arrow"
                    />
                  </p>
                  <span>PDF * 1MB </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="nine">
        {/* <div class="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active text"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            The world’s biggest crypto lender Nexo announced it officially
            backed its first mortgage using the cryptocurrency so “Mighty Ducks”
            actor Brock Pierce could buy a $1.2 million dollar home.
          </div>
          <div
            className="tab-pane fade text"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            The world’s biggest crypto lender Nexo announced it officially
            backed its first mortgage using the cryptocurrency so “Mighty Ducks”
            actor Brock Pierce could buy a $1.2 million dollar home.
          </div>
          <div
            class="tab-pane fade text"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            The world’s biggest crypto lender Nexo announced it officially
            backed its first mortgage using the cryptocurrency so “Mighty Ducks”
            actor Brock Pierce could buy a $1.2 million dollar home.
          </div>
        </div>
        <hr className="hrrr" />
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <span
            class="active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            <img src={FoxLogo} alt="fox-logo" className="img-fluid" />
          </span>

          <span
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            <img src={Bloomberg} alt="fox-logo" className="img-fluid" />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img src={Yahoo} alt="fox-logo" className="img-fluid smaller" />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img src={Forbes} alt="fox-logo" className="img-fluid smaller" />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img src={Coindesk} alt="fox-logo" className="img-fluid smaller" />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img
              src={CoinTelegrah}
              alt="fox-logo"
              className="img-fluid smaller"
            />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img src={Reuters} alt="fox-logo" className="img-fluid smaller" />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img src={CNBS} alt="fox-logo" className="img-fluid small" />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img
              src={BitcoinImg}
              alt="fox-logo"
              className="img-fluid smaller"
            />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img
              src={SeekingAlpha}
              alt="fox-logo"
              className="img-fluid smaller"
            />
          </span>

          <span
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            <img
              src={Deroaktoinar}
              alt="fox-logo"
              className="img-fluid smaller"
            />
          </span>
        </ul>
       */}
      </section>

      <section className="ten">
        <div className="container">
          <div className="row">
            <div className="col-5 left">
              <h4>Unleash the</h4>
              <h2>Power of Your Crypto</h2>
              <p>
                With the account that caters to your profit and prosperity
                through our leading credit line service for digital assets and
                high-yield interest on your idle savings.
              </p>
              <div className="account-div">
                <button>
                  Create Account
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="col-7">
              <img src={CryptoPowerImg} alt="crypto-power" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;
